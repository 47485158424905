body {
  background: #e7edee;
}

.Splash {
  text-align: center;
}

.Splash iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  border: none;
}
